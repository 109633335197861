<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png" alt @click="goBack" />
        <span class="center">用户管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">发票申请列表</span>
        <img src="../../assets/right.png" alt />
        <span class="center">开票详情</span>
      </div>
    </div>

    <el-card>
      <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>基本信息</span>
          </div>
        </div>
        <el-row>
          <el-col :span="11" class="text-align">
            <div class="left-demo">
              <div class="left-demo_left">
                <img src="../../assets/invoice_1.png" alt />
                <span>发票申请编号:</span>
              </div>
              <span class="left-demo_right">{{ invoiceInfo.code || "--" }}</span>
            </div>
            <div class="left-demo">
              <div class="left-demo_left">
                <img src="../../assets/invoice_2.png" alt />
                <span>开票状态：</span>
              </div>
              <span class="left-demo_right">
                <span style="color: #f56c6c" v-if="!invoiceInfo.complate">未开票</span>
                <span style="color: #67c23a" v-if="invoiceInfo.complate">已开票</span>
              </span>
            </div>
            <div class="left-demo">
              <div class="left-demo_left">
                <img src="../../assets/invoice_3.png" alt />
                <span>
                  开票内容：{{
                  }}
                </span>
              </div>
              <span class="left-demo_right">{{ invoiceInfo.remark || "--" }}</span>
            </div>
          </el-col>
          <el-col :span="12" class="text-align" :offset="1">
            <div class="left-demo">
              <div class="left-demo_left">
                <img src="../../assets/invoice_4.png" alt />
                <span>申请企业名称：</span>
              </div>
              <span class="left-demo_right">{{ invoiceInfo.enterpriseName || "--" }}</span>
            </div>
            <div class="left-demo">
              <div class="left-demo_left">
                <img src="../../assets/invoice_5.png" alt />
                <span>开票种类：</span>
              </div>
              <span class="left-demo_right">{{ invoiceInfo.invoiceType || "--" }}</span>
            </div>
            <div class="left-demo">
              <div class="left-demo_left">
                <img src="../../assets/invoice_6.png" alt />
                <span>发票申请时间：</span>
              </div>
              <span class="left-demo_right">{{ invoiceInfo.createTime | dateVal }}</span>
            </div>
          </el-col>
          <!-- <el-col class="el-col-lineheight" :span="12">
            开票编号：{{ invoiceInfo.code }}
          </el-col>
          <el-col class="el-col-lineheight" :span="12">
            开票状态：
            <span style="color: #f56c6c" v-if="!invoiceInfo.complate"
              >未开票</span
            >
            <span style="color: #67c23a" v-if="invoiceInfo.complate"
              >已开票</span
            >
          </el-col>
          <el-col class="el-col-lineheight" :span="12">
            申请企业名称：{{ invoiceInfo.enterpriseName }}
          </el-col>
          <el-col class="el-col-lineheight" :span="12">
            发票申请时间：
            {{ invoiceInfo.createTime | dateVal }}
          </el-col>
          <el-col class="el-col-lineheight" :span="24">
            开票种类：{{ invoiceInfo.invoiceType || " - " }}
          </el-col>
          <el-col class="el-col-lineheight" :span="24">
            开票内容：{{ invoiceInfo.remark }}
          </el-col>-->
        </el-row>
      </el-card>

      <el-card class="box-card">
        <div class="left_top" style="margin:15px 0">
          <div class="left_icon">
            <span></span>
            <span>工资包列表</span>
          </div>
          <el-button
            type="text"
            v-if="!invoiceInfo.complate"
            @click="AddPackage"
            style="padding:0"
            v-access
            data-ctrl="invoiceSelectInvoicePackage"
          >添加工资包</el-button>
        </div>
        <el-table :data="packList" style="width: 100%">
          <el-table-column label="工资包名称">
            <template slot-scope="scope">
              <span
                class="clickColor"
                @click="goDetail({ code: scope.row.code })"
              >{{ scope.row.name }}</span>
            </template>
          </el-table-column>

          <el-table-column label="结算中心">
            <template slot-scope="scope">{{ scope.row.pay_platform | platformVal }}</template>
          </el-table-column>
          <el-table-column label="结算金额/元">
            <template slot-scope="scope">{{ scope.row.total | money }}</template>
          </el-table-column>
          <el-table-column label="发包时间">
            <template slot-scope="scope">{{ scope.row.releaseTime | dateVal }}</template>
          </el-table-column>
          <el-table-column label="操作" width="150" v-if="!invoiceInfo.complate">
            <template slot-scope="scope">
              <el-button type="text" @click="deletePackage(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: right;margin-top: 10px;font-size: 14px;">
          <div>共选中工资包：{{ packNums }} 个</div>
          <div>开票总金额：{{ packTotal | money }} 元</div>
        </div>
      </el-card>
      <el-card class="box-card">
        <div class="left_top" style="margin:15px 0">
          <div class="left_icon">
            <span></span>
            <span>发票</span>
          </div>
          <div slot="right" v-show="!invoiceInfo.complate" v-access data-ctrl="invoiceAdd">
            <el-upload :show-file-list="false" :action="uploadPath" :on-success="onUploadSuccess">
              <el-button type="text" style="padding:0">上传发票</el-button>
            </el-upload>
          </div>
        </div>
        <el-table :data="invoiceList" style="width: 100%">
          <el-table-column label="发票号及编码" width="200">
            <template slot-scope="scope">
              <div>发票号：{{ scope.row.invoiceCode }}</div>
              <div>发票编码：{{ scope.row.invoiceNumber }}</div>
              <div>
                发票图片：
                <a :href="scope.row.path" target="_blank">
                  <el-button type="text">查看</el-button>
                </a>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="购买方">
            <template slot-scope="scope">
              <div>名称：{{ scope.row.draweeName }}</div>
              <div>统一信用代码：{{ scope.row.draweeTaxNumber }}</div>
              <div>地址、电话：{{ scope.row.draweePhone }}</div>
              <div>开户行及帐号：{{ scope.row.draweeBank }}</div>
            </template>
          </el-table-column>
          <el-table-column label="销售方">
            <template slot-scope="scope">
              <div>名称：{{ scope.row.salesName }}</div>
              <div>统一信用代码：{{ scope.row.salesTaxNumber }}</div>
              <div>地址、电话：{{ scope.row.salesPhone }}</div>
              <div>开户行及帐号：{{ scope.row.salesBank }}</div>
            </template>
          </el-table-column>

          <el-table-column label="金额/元" width="150">
            <template slot-scope="scope">{{ scope.row.pureAmount | money }}</template>
          </el-table-column>

          <el-table-column label="税额" width="150">
            <template slot-scope="scope">{{ scope.row.tax | money }}元</template>
          </el-table-column>

          <el-table-column label="合计" width="150">
            <template slot-scope="scope">{{ scope.row.amount | money }}元</template>
          </el-table-column>

          <el-table-column label="操作" width="150" v-if="!invoiceInfo.complate">
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="deleteInvoice(scope.row)"
                v-access
                data-ctrl="invoiceDel"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
      <el-card class="box-card" style="margin-bottom:20px;">
        <!-- <Divider :title="'快递信息'"></Divider> -->
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>快递信息</span>
          </div>
        </div>
        <div>
          <el-row>
            <el-col class="el-col-lineheight" :span="12">快递公司：{{ invoiceInfo.expressage || "暂无" }}</el-col>
            <el-col
              class="el-col-lineheight"
              :span="12"
            >快递单号：{{ invoiceInfo.expressageOdd || "暂无" }}</el-col>
          </el-row>
        </div>
      </el-card>
    </el-card>
    <AddPackage
      :dialogVisible.sync="addDialogVisible"
      :account="account"
      :invoiceCode="invoiceCode"
      @close="getTaskList"
    ></AddPackage>
  </div>
</template>

<script>
//接口：
// import config from "/src/config/config";
import * as _ from 'lodash';
import { api } from '/src/api/base';

// 查询发票申请详情
export const invoiceApplyDescribe = api()('invoiceApply.describe.json');
// 查询工资包列表
export const taskList = api()('task.list.json');
// 删除选中工资包
export const invoiceUnselectInvoicePackage = api()(
  'invoice.unselect.invoice.package.json'
);

//查询发票列表
export const invoiceList = api()('invoice.list.json');
// 删除发票
export const invoiceDel = api()('invoice.del.json');

export default {
  data () {
    return {
      invoiceCode: '',
      enterpriseAccount: '',
      invoiceInfo: {},
      invoiceEnterpriseInfo: {},

      packList: [],
      invoiceList: [],
      account: '',
      addDialogVisible: false,
      packNums: 0,
      packTotal: '',
      uploadPath: ''
    };
  },
  created () {
    this.invoiceCode = this.$route.query.code;
    this.enterpriseAccount = this.$route.query.enterpriseAccount;
    this.getinvoiceInfo();
    this.getTaskList();
    this.getInvoiceList();
    this.uploadPath = `${window.location.origin}/invoice.add.json?applyCode=${this.invoiceCode}`;
  },
  methods: {
    async getInvoiceList () {
      const res = await invoiceList({
        applyCode: this.invoiceCode
      });
      this.invoiceList = res.list || [];
    },
    AddPackage () {
      this.addDialogVisible = true;
      this.account = this.invoiceInfo.enterpriseAccount;
    },
    async getTaskList () {
      const res = await taskList({
        keywords: '',
        pageNumber: 1,
        pageSize: 100,
        invoiceApplyCode: this.invoiceCode
      });
      this.packList = res.list;
      this.packNums = this.packList.length;
      this.packTotal = _.sum(this.packList.map(it => it.total * 1));
    },
    async getinvoiceInfo () {
      this.invoiceInfo =
        (await invoiceApplyDescribe({ code: this.invoiceCode })) || {};
    },
    async deletePackage (row) {
      await invoiceUnselectInvoicePackage({
        applyCode: this.invoiceCode,
        packageCodes: row.code
      });
      this.$message.success(`工资包${row.name}删除成功`);
      this.getTaskList();
    },
    async deleteInvoice (row) {
      await invoiceDel({
        // applyCode: this.invoiceCode,
        code: row.code
      });
      this.$message.success('删除成功');
      this.getInvoiceList();
    },
    onUploadSuccess (item, $event) {
      console.log(item, $event);
      this.$message.success('上传成功！');
      this.getInvoiceList();
    },
    goBack () {
      this.$router.go(-1);
    },
    goDetail (row) {
      console.log(row);
      this.$router.push({
        path: '/system/task/detail',
        query: {
          code: row.code
        }
      });
    }
  },
  components: {
    // Divider: () => import("/src/components/divider"),
    AddPackage: () => import('./platform-select-package.vue')
  }
};
</script>

<style scoped lang="scss">
.el-col-lineheight {
  line-height: 36px;
  font-size: 14px;
  text-align: left;
}
.text-align {
  .left-demo {
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 20px 10px;
    border-bottom: 1px dashed #000;
    font-size: 15px;
    .left-demo_left {
      display: flex;
      align-items: center;
      img {
        width: 39px;
        height: 35px;
      }
      span {
        margin-left: 15px;
      }
    }
    .left-demo_right {
      color: #b4b4b4;
    }
  }
  .left-demo:last-child {
    border: none;
  }
}
</style>
